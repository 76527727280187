import { graphql } from 'gatsby'
import React, { useContext, useEffect, useState, useCallback } from 'react'
// import ContentBlock from "../components/Slices/ContentBlock";
// import Features from "../components/Slices/Features";
import TileCards from '../components/Slices/Tiles'
import BodyWrapper from '../components/Styled/BodyWrapper'
import { Container } from '../components/Styled/Grid.js'
import { theme } from '../styles/theme'
import Seo from '../components/Global/Seo'
import { Image, Box, ResponsiveContext } from 'grommet'
import { useLocation } from '@reach/router'
import { parse } from 'query-string'
import shapes2 from '../assets/images/shapes2.png'
import shapes from '../assets/images/shapes.png'
import { useDispatch } from 'react-redux'
import { addVariantToCart } from '../State/cart/operations'
import BuyButton from '../components/Styled/BuyButton'
import axios from 'axios'
import { Bullet, Title, SubTitle, BodyText } from '../components/Styled/Type'
import { colors } from '../components/Styled/Colors'
import Layout from '../components/Global/Layout'

// import Slice from "../components/Styled/Slice";

const Banner = () => {
  const size = useContext(ResponsiveContext)
  const location = useLocation()
  const [source, setSource] = useState(null)
  const [info, setInfo] = useState(null)
  const dispatch = useDispatch()

  const addToCart = (id, variantQuantity, trackingObject = {}) => {
    dispatch(addVariantToCart(id, variantQuantity, trackingObject))
  }

  const getCurrentJot = useCallback(async () => {
    try {
      let { data } = await axios.get(
        'https://api.joto.io/jots/search?releasedAt=true&sortDirection=DESC&sort=releasedAt&pro=all&category=365&limit=1'
      )
      let { jotId, userId, svgFilename, title, desc } = data[0] || {}
      let opengraphUrl64 = new Buffer(
        `https://joto.imgix.net/protected/${userId}/jots/${svgFilename}?fm=png`
      ).toString('base64')
      let joto = `jot_front_png.png`
      let composition = `https://jotowebsite.imgix.net/${joto}?ar=${encodeURI(
        '1:1.5'
      )}&fit=crop&h=${600}&blend64=${opengraphUrl64}&blend-mode=normal&blend-h=${400}&blend-y=145`
      setSource(composition)
      let {
        data: { handle, name },
      } = await axios.get(`https://api.joto.io/users/profile/${userId}`)
      let description = `Illustration by @${handle}`
      setInfo({ title, handle, name, desc: description })
    } catch (err) {
      console.log(err)
    }
  }, [info])

  useEffect(() => {
    const { payload } = parse(location.search)
    if (payload) {
      let buff = new Buffer(payload, 'base64')
      const { url, jotId, handle, name, title, desc } = parse(
        buff.toString('ascii')
      )
      let opengraphUrl64 = new Buffer(url).toString('base64')
      let joto = `jot_front_png.png`
      let composition = `https://jotowebsite.imgix.net/${joto}?ar=${encodeURI(
        '1:1.5'
      )}&fit=crop&h=${600}&blend64=${opengraphUrl64}&blend-mode=normal&blend-h=${400}&blend-y=145`
      setSource(composition)
      setInfo({ url, jotId, handle, name, title, desc })
    } else {
      getCurrentJot()
    }
  }, [])

  useEffect(() => {
    console.log('effect', info)
  }, [info])

  return (
    <Box
      height={{min: size !== 'small' ? '50vh' : 'auto'}}
      fill={'horizontal'}
      direction={size !== 'small' ? 'row' : 'column-reverse'}
      style={{ overflow: 'hidden' }}
      background={'#EEF3E9'}
      justify={'center'}
      align={'center'}
      pad={'large'}
    >
      <Box
        direction={size !== 'small' ? 'row' : 'column-reverse'}
        width={{ max: 'xxlarge' }}
        fill={true}
        responsive
      >
        <Box
          basis={'1/2'}
          align={'center'}
          justify={'center'}
          style={{ position: 'relative' }}
        >
        

          {info && (
            <Box style={{ position: 'relative',  zIndex: 100}}>
              <Bullet margin={'none'} color={colors.purple}>
                {'Todays 365 Days of Art Jot'}
              </Bullet>
              <h1>{info.title}</h1>
              <h4>{info.desc}</h4>
              <BodyText>
                Wake up to a new piece of art like this, on on your wall
                everyday. Now drawn automatically at a time of your choice with
                our brand new playlist feature.
              </BodyText>
              <BodyText>Available now with a 365 Days Of Art subscription.</BodyText>
              <BuyButton
                onClick={() =>
                  addToCart(
                    'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yMTk4MzAxMDA2MjQ1NA==',
                    1
                  )
                }
              >
                Subscribe Now
              </BuyButton>
            </Box>
          )}
        </Box>
        <Box
          height={{max: "50vh"}}
          basis={size !== 'small' ? '1/2' : 'flex'}
          style={{ position: 'relative' }}
          fill={true}
        >
          <img
            style={{
              zIndex: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
              left: 0,
              margin: '0 auto',
            }}
            src={shapes}
          />
          {source && (
            <Image
              style={{ position: 'relative', zIndex: 1 }}
              width={'100%'}
              max={'100%'}
              src={source}
              fit={'contain'}
              fill={true}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

const BlogComponent = ({
  data: {
    prismicBlogFeed: {
      data: { body },
    },
  },
}) => {
  let promoted = body[0].items
  let cards = body[1].items

  return (
    <Layout>
      <Seo />
      <BodyWrapper>
        <Banner />
        <Container color={theme.color.backgroundShade}>
          <TileCards promoted items={promoted} />
          <TileCards items={cards} />
        </Container>
      </BodyWrapper>
    </Layout>
  )
}

export const query = graphql`
  {
    prismicBlogFeed {
      data {
        blog_banner_image_left {
          url
        }
        blog_banner_image_right {
          url
        }
        blog_feed_banner_text {
          text
        }
        blog_feed_banner_subtitle {
          text
        }
        body {
          ... on PrismicBlogFeedBodyBlogPost {
            items {
              blog_tile_image {
                url
              }
              blog_post {
                document {
                  ... on PrismicBlog {
                    uid
                    data {
                      blog_image {
                        fluid {
                          src
                        }
                      }
                      blog_title {
                        html
                        text
                      }
                      subtitle {
                        html
                        text
                      }
                      excerpt {
                        html
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicBlogFeedBodyPromotedContent {
            items {
              promoted_blog_post {
                document {
                  ... on PrismicBlog {
                    uid
                    data {
                      blog_image {
                        fluid {
                          src
                        }
                      }
                      blog_title {
                        html
                        text
                      }
                      subtitle {
                        html
                        text
                      }
                      excerpt {
                        html
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BlogComponent
