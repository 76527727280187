import React from "react";
import { ContentRow } from "../../components/Styled/Grid";
import Card from "../Elements/Card";

const Tile = ({
  uid,
  item: { blog_title, blog_excerpt, subtitle, excerpt, blog_image},
  promoted,
}) => {
  return (
    <Card
      small={!promoted}
      center
      text={promoted && excerpt ? excerpt.text : null}
      category={subtitle.text}
      title={blog_title.text}
      img={blog_image && blog_image.fluid.src}
      link={`/discover/${uid}`}
    />
  );
};

export const TileCards = ({ items, promoted }) => {
  return (
    <ContentRow col={promoted ? "2" : "3"}>
      {items && promoted
        ? items.map((item, key) => (
            <Tile
              key={key}
              promoted
              item={item.promoted_blog_post.document.data}
              uid={item.promoted_blog_post.document.uid}
            />
          ))
        : items.map((item, key) => (
            <Tile key={key} item={item.blog_post.document.data} uid={item.blog_post.document.uid}/>
          ))}
    </ContentRow>
  );
};

export default TileCards;
