import React from 'react'
import {
  Container,
  Body,
  ContainerInner,
  Image,
  Category,
  Title,
  Text,
} from '../Styled/Card'
import { Box } from 'grommet'
import { Link } from 'gatsby'

const CardComponent = ({
  link,
  img,
  title,
  category,
  text,
  small,
  center,
  create,
}) => {
  return (
    <Link to={link}>
      <Box>
        <Container small={small} link={link ? true : false}>
          <ContainerInner>
            <Image img={img} />
            <Body>
              <Category small={small} center={center}>
                {category}
              </Category>
              <Title small={small} center={center}>
                {title}
              </Title>
              {!small && (
                <Text small={small} center={center}>
                  {text}
                </Text>
              )}
            </Body>
          </ContainerInner>
        </Container>
      </Box>
    </Link>
  )
}

export default CardComponent
